import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom'
import './App.scss';

import authClient from './auth';

import Loadable from 'react-loadable';
import jquery from 'jquery';
// import Login from './views/Pages/Login'
window.$ = window.jQuery=jquery;
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const dotenv = require('dotenv');
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});
const Public = () => <h3>Public</h3>

// Pages
// const Login = Loadable({
//   loader: () => import('./views/Pages/Login'),
//   loading
// });

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});


const PrivateRoute = ({ component: Component, ...rest }) => {
  // if (window.location.pathname !== '/login') {
    return (
      <Route {...rest} render={(props) => (
        authClient.isAuthenticated() === true
          ? <Component {...props} />
          : <Redirect to="/login" />
      )} />
    )
  // } else {
  //   return(
  //     <Route path="/login" component={Login} />
  //   )
  // }
}

export default function AuthExample () {
  return (
    <Router>
      <div>
        <Route path="/public" component={Public}/>
        <Route path="/login" component={Login}/>
        <PrivateRoute path='/' component={DefaultLayout} />
      </div>
    </Router>
  )
}
