import axios from 'axios';

class Auth {
  constructor() {

    this.token = localStorage.getItem('authenticity_token')

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile(env) {
    axios.get(process.env.REACT_APP_API_HOST+'/api/users/', { headers: {"authorization": "Token 2096d1a32d83ab99cb9ab5b215c8ad4e3bba6ab6"} })
      .then(res => {
      })
  }
  isAuthenticated() {
    return this.token != undefined
  }

  signIn(user) {
    let userData = user
    axios.post(process.env.REACT_APP_API_HOST+'/rest-auth/login/',JSON.stringify(userData),{headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.data['non_field_errors'] != undefined) {
        console.log(res.data['non_field_errors']);
      }else {
        localStorage.setItem('authenticity_token', res.data['key']);
        localStorage.setItem('username', userData.username);
        localStorage.removeItem('error');
        window.location.reload(true);
      }
    }).catch(error => {

      if(error.response){
        let error_messages = error.response.statusText + "<br>"
        for (var key in error.response.data){
          error_messages += error.response.data[key] + "<br>"
        }
        document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
            '<span aria-hidden="true">×</span></button>'+
            error_messages +
        '</div>')
      }
    });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000;
        resolve();
      });
    })
  }

  signOut() {
    // clear id token, profile, and expiration
    axios.get(process.env.REACT_APP_API_HOST+'/rest-auth/logout/',{ headers: { "Authorization": "Token " + localStorage.getItem('authenticity_token') }}).then(res => {
      this.idToken = null;
      this.profile = null;
      this.expiresAt = null;
      localStorage.removeItem('authenticity_token');
      localStorage.removeItem('username');
      window.location.reload(true);
    }).catch(error => {
      if(error.response){
        let error_messages = error.response.statusText + "<br>"
        for (var key in error.response.data){
          error_messages += error.response.data[key] + "<br>"
        }
        document.getElementById('fixed-error-div').innerHTML = ('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
          '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">'+
            '<span aria-hidden="true">×</span></button>'+
            error_messages +
        '</div>')
      }
    });
  }
}

const authClient = new Auth();

export default authClient;
